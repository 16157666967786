<template>
    <div>
        <div class="page-header justify-content-start">
            <lable class="page-title">Notification</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
        <div class="notifications_wrap tasks-section">
          <label class="notification_title">Unread</label>
          <div class="task-wrap">
            <label class="day_title"><span>21st Nov '20</span></label>
            <div class="task-list high">
              <ul>
                  <li class="category">
                      <div class="task-item">
                          <label>CATEGORY</label>
                          <div class="task-content"><span>administration</span></div>
                      </div>
                      <div class="task-item">
                          <label>DATE/TIME</label>
                          <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Priority</label>
                          <div class="task-content">
                              <p>High</p>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>Estimated time</label>
                          <div class="task-content">
                              <p>5 Mins</p>
                          </div>
                      </div>
                  </li>
                  <li class="subject">
                      <div class="task-item">
                          <label>SUBJECT</label>
                          <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                      </div>
                      <div class="task-item">
                          <label>DESCRIPTION</label>
                          <div class="task-content">
                              <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                          </div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Assigned by</label>
                          <div class="task-content">
                              <div class="profile-sec">
                                  <figure><img src="@/assets/images/user.png"></figure>
                                  <figcaption>Meenal Shah</figcaption>
                              </div>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>ATTACHMENT</label>
                          <div class="task-content">
                              <div class="attachments">
                                  <small>4</small>
                              </div>
                          </div>
                      </div>
                  </li>  
                  <li class="task-actions alert_actions">
                      <div class="task-item">
                          <label>Action</label>
                          <div class="task-content">
                              <p>START</p>
                              <p>Archive</p>
                              <p>View Details</p>
                          </div> 
                      </div> 
                  </li>
              </ul>
            </div>
            <div class="task-list low">
              <ul>
                  <li class="category">
                      <div class="task-item">
                          <label>CATEGORY</label>
                          <div class="task-content"><span>administration</span></div>
                      </div>
                      <div class="task-item">
                          <label>DATE/TIME</label>
                          <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Priority</label>
                          <div class="task-content">
                              <p>Low</p>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>Estimated time</label>
                          <div class="task-content">
                              <p>5 Mins</p>
                          </div>
                      </div>
                  </li>
                  <li class="subject">
                      <div class="task-item">
                          <label>SUBJECT</label>
                          <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                      </div>
                      <div class="task-item">
                          <label>DESCRIPTION</label>
                          <div class="task-content">
                              <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                          </div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Assigned by</label>
                          <div class="task-content">
                              <div class="profile-sec">
                                  <figure><img src="@/assets/images/user.png"></figure>
                                  <figcaption>Meenal Shah</figcaption>
                              </div>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>ATTACHMENT</label>
                          <div class="task-content">
                              <div class="attachments">
                                  <small>4</small>
                              </div>
                          </div>
                      </div>
                  </li>  
                  <li class="task-actions alert_actions">
                      <div class="task-item">
                          <label>Action</label>
                          <div class="task-content">
                              <p>START</p>
                              <p>Archive</p>
                              <p>View Details</p>
                          </div> 
                      </div> 
                  </li>
              </ul>
            </div>
            <div class="task-list medium">
              <ul>
                  <li class="category">
                      <div class="task-item">
                          <label>CATEGORY</label>
                          <div class="task-content"><span>administration</span></div>
                      </div>
                      <div class="task-item">
                          <label>DATE/TIME</label>
                          <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Priority</label>
                          <div class="task-content">
                              <p>Medium</p>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>Estimated time</label>
                          <div class="task-content">
                              <p>5 Mins</p>
                          </div>
                      </div>
                  </li>
                  <li class="subject">
                      <div class="task-item">
                          <label>SUBJECT</label>
                          <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                      </div>
                      <div class="task-item">
                          <label>DESCRIPTION</label>
                          <div class="task-content">
                              <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                          </div>
                      </div>
                  </li>
                  <li class="owner">
                      <div class="task-item">
                          <label>Assigned by</label>
                          <div class="task-content">
                              <div class="profile-sec">
                                  <figure><img src="@/assets/images/user.png"></figure>
                                  <figcaption>Meenal Shah</figcaption>
                              </div>
                          </div>
                      </div>
                      <div class="task-item">
                          <label>ATTACHMENT</label>
                          <div class="task-content">
                              <div class="attachments">
                                  <small>4</small>
                              </div>
                          </div>
                      </div>
                  </li>  
                  <li class="task-actions alert_actions">
                      <div class="task-item">
                          <label>Action</label>
                          <div class="task-content">
                              <p>START</p>
                              <p>Archive</p>
                              <p>View Details</p>
                          </div> 
                      </div> 
                  </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="notifications_wrap tasks-section">
          <label class="notification_title">Read</label>
          <div class="task-wrap">
            <label class="day_title"><span>21st Nov '20</span></label>
             <div class="task-list high">
                <ul>
                    <li class="category">
                        <div class="task-item">
                              
                            <div class="task-content"><span>administration</span></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content date">
                                <p>21st Nov '20 </p>
                                <p>11:00 am - 1:00 pm</p>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                              
                            <div class="task-content">
                                  
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content location">
                                <p>Mumbai</p><br/>
                                <a>Taj Western<img src="@/assets/images/info.svg"></a>
                            </div>
                        </div>
                    </li>
                    <li class="subject">
                        <div class="task-item">
                              
                            <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                            
                            <div class="task-content">
                                <ul class="members_list">
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li><figure><img src=""></figure></li>
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li class="more"><span>+1</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="attachments">
                                    <small>4</small>
                                </div>
                            </div>
                        </div>
                    </li>  
                    <li class="task-actions alert_actions2">
                        <div class="task-item">
                              
                            <div class="task-content">
                                <p>START</p>
                                <p>View Details</p> 
                            </div> 
                        </div> 
                        <div class="task-item">
                              
                        </div>
                    </li>
                </ul>
            </div>
            <div class="task-list low">
                <ul>
                    <li class="category">
                        <div class="task-item">
                              
                            <div class="task-content"><span>administration</span></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content date">
                                <p>21st Nov '20 </p>
                                <p>11:00 am - 1:00 pm</p>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                              
                            <div class="task-content">
                                  
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content location">
                                <p>Mumbai</p><br/>
                                <a>Taj Western<img src="@/assets/images/info.svg"></a>
                            </div>
                        </div>
                    </li>
                    <li class="subject">
                        <div class="task-item">
                              
                            <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                            
                            <div class="task-content">
                                <ul class="members_list">
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li><figure><img src=""></figure></li>
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li class="more"><span>+1</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="attachments">
                                    <small>4</small>
                                </div>
                            </div>
                        </div>
                    </li>  
                    <li class="task-actions alert_actions2">
                        <div class="task-item">
                              
                            <div class="task-content">
                                <p>START</p>
                                <p>View Details</p> 
                            </div> 
                        </div> 
                        <div class="task-item">
                              
                        </div>
                    </li>
                </ul>
            </div>
            <div class="task-list medium">
                <ul>
                    <li class="category">
                        <div class="task-item">
                              
                            <div class="task-content"><span>administration</span></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content date">
                                <p>21st Nov '20 </p>
                                <p>11:00 am - 1:00 pm</p>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                              
                            <div class="task-content">
                                  
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content location">
                                <p>Mumbai</p><br/>
                                <a>Taj Western<img src="@/assets/images/info.svg"></a>
                            </div>
                        </div>
                    </li>
                    <li class="subject">
                        <div class="task-item">
                              
                            <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                            </div>
                        </div>
                    </li>
                    <li class="owner">
                        <div class="task-item">
                            
                            <div class="task-content">
                                <ul class="members_list">
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li><figure><img src=""></figure></li>
                                    <li><figure><img src="@/assets/images/user.png"></figure></li>
                                    <li class="more"><span>+1</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="task-item">
                              
                            <div class="task-content">
                                <div class="attachments">
                                    <small>4</small>
                                </div>
                            </div>
                        </div>
                    </li>  
                    <li class="task-actions alert_actions2">
                        <div class="task-item">
                              
                            <div class="task-content">
                                <p>START</p>
                                <p>View Details</p> 
                            </div> 
                        </div> 
                        <div class="task-item">
                              
                        </div>
                    </li>
                </ul>
            </div>
          </div>
        </div>
    </div>
</template>

<script> 
  export default {
    components: {
         
    },
 
  }
</script>